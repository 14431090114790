export const RelativesPageConstant = {
    relatives: 'Reception_Management_Relatives.relatives',
    relative: 'Reception_Management_Relatives.relative',
    name: 'Reception_Management_Relatives.name',
    firstName: 'Reception_Management_Relatives.firstname',
    lastName: 'Reception_Management_Relatives.lastname',
    firstAddress: 'Reception_Management_Relatives.firstaddress',
    secondAddress: 'Reception_Management_Relatives.secondaddress',
    phoneNumber: 'Reception_Management_Relatives.phonenumber',
    whatsappNumber: 'Reception_Management_Relatives.whatsappnumber',
    isVoucher: 'Reception_Management_Relatives.isvoucher',
    email: 'Reception_Management_Relatives.email',
    icon: 'Reception_Management_Relatives.icon',
    image: 'Reception_Management_Relatives.image',
    actions: 'Reception_Management_Relatives.actions',
    code: 'Reception_Management_Relatives.code',
    info: 'Reception_Management_Relatives.info',
    search: 'Reception_Management_Relatives.search',
    reset: 'Reception_Management_Relatives.reset',
    uploadIcon: 'Reception_Management_Relatives.uploadicon',
    selectLanguage: 'Reception_Management_Relatives.selectlanguage',
    createdDate: 'Reception_Management_Relatives.createddate',
    updatedDate: 'Reception_Management_Relatives.updateddate'
};
