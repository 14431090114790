export const AdministrationAccountsPageConstant = {
    accounts: 'Administration_Accounts.accounts',
    account: 'Administration_Accounts.account',
    username: 'Administration_Accounts.username',
    accountName: 'Administration_Accounts.accountname',
    email: 'Administration_Accounts.email',
    resetPassword: 'Administration_Accounts.resetpassword',
    password: 'Administration_Accounts.password',
    member: 'Administration_Accounts.member',
    activationFrom: 'Administration_Accounts.activationfrom',
    activationTo: 'Administration_Accounts.activationto',
    isActive: 'Administration_Accounts.isactive',
    newPassword: 'Administration_Accounts.newpassword',
    confirmedNewPassword: 'Administration_Accounts.confirmednewpassword',
    cancel: 'Administration_Accounts.cancel',
    reset: 'Administration_Accounts.reset',
    activated: 'Administration_Accounts.activated',
    role: 'Administration_Accounts.role',
    section: 'Administration_Accounts.section',
    category: 'Administration_Accounts.category',
    jobTitle: 'Administration_Accounts.jobtitle',
    search: 'Administration_Accounts.search',
    assignRole: 'Administration_Accounts.assignrole',
    roles: 'Administration_Accounts.roles',
    save: 'Administration_Accounts.save'
};
