export const PatientAppointmentsPageConstant = {
    patientAppointments: 'Reception_Management_Patient_Appointments.patientappointments',
    typeAppointments: 'Reception_Management_Patient_Appointments.typeappointments',
    visits: 'Reception_Management_Patient_Appointments.visits',
    patientAppointment: 'Reception_Management_Patient_Appointments.patientappointment',
    patients: 'Reception_Management_Patient_Appointments.patients',
    patient: 'Reception_Management_Patient_Appointments.patient',
    appointmentTypes: 'Reception_Management_Patient_Appointments.appointmenttypes',
    reservationTypes: 'Reception_Management_Patient_Appointments.reservationtypes',
    medicalVisitTypes: 'Reception_Management_Patient_Appointments.medicalvisittypes',
    doctor: 'Reception_Management_Patient_Appointments.doctor',
    doctors: 'Reception_Management_Patient_Appointments.doctors',
    appointments: 'Reception_Management_Patient_Appointments.appointments',
    complaint: 'Reception_Management_Patient_Appointments.complaint',
    code: 'Reception_Management_Patient_Appointments.code',
    isWaitting: 'Reception_Management_Patient_Appointments.iswaitting',
    fileNumber: 'Reception_Management_Patient_Appointments.filenumber',
    patientName: 'Reception_Management_Patient_Appointments.patientname',
    actions: 'Reception_Management_Patient_Appointments.actions',
    addPatient: 'Reception_Management_Patient_Appointments.addpatient',
    createdDate: 'Reception_Management_Patient_Appointments.createddate',
    updatedDate: 'Reception_Management_Patient_Appointments.updateddate',
    externalReferral: 'Reception_Management_Patient_Appointments.externalreferral',
    internalReferral: 'Reception_Management_Patient_Appointments.internalreferral',
    medicalReferral: 'Reception_Management_Patient_Appointments.medicalreferral',
    addNewdMedicalReferral: 'Reception_Management_Patient_Appointments.addnewdmedicalreferral',
    addExternalFromList: 'Reception_Management_Patient_Appointments.addexternalfromlist',
    name: 'Reception_Management_Patient_Appointments.name',
    description: 'Reception_Management_Patient_Appointments.description',
    icon: 'Reception_Management_Patient_Appointments.icon',
    selectAppointment: 'Reception_Management_Patient_Appointments.selectappointment',
    visitReaseon: 'Reception_Management_Patient_Appointments.visitreaseon',
    notes: 'Reception_Management_Patient_Appointments.notes',
    status: 'Reception_Management_Patient_Appointments.status',
    appointmentDate: 'Reception_Management_Patient_Appointments.appointmentdate',
    patientEnterToRoomDate: 'Reception_Management_Patient_Appointments.patiententertoroomdate',
    medicalVisitStartDate: 'Reception_Management_Patient_Appointments.medicalvisitstartdate',
    medicalVisitEndDate: 'Reception_Management_Patient_Appointments.medicalvisitenddate',
    todayAppointments: 'Reception_Management_Patient_Appointments.todayappointments',
    allAppointments: 'Reception_Management_Patient_Appointments.allappointments',
    pleaseSelectAppointment: 'Reception_Management_Patient_Appointments.pleaseselectappointment',
    patientVisits: 'Reception_Management_Patient_Appointments.patientvisits',
    showVisits: 'Reception_Management_Patient_Appointments.showvisits',
    visitDetails: 'Reception_Management_Patient_Appointments.visitdetails'
};
