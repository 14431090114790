export const MedicalReferralsPageConstant = {
    medicalReferrals: 'Reception_Management_Medical_Referrals.medicalreferrals',
    medicalReferral: 'Reception_Management_Medical_Referrals.medicalreferral',
    name: 'Reception_Management_Medical_Referrals.name',
    patient: 'Reception_Management_Medical_Referrals.patient',
    medicalReferralType: 'Reception_Management_Medical_Referrals.medicalreferraltype',
    toDoctor: 'Reception_Management_Medical_Referrals.todoctor',
    fromInternalDoctor: 'Reception_Management_Medical_Referrals.frominternaldoctor',
    formExternal: 'Reception_Management_Medical_Referrals.formexternal',
    external: 'Reception_Management_Medical_Referrals.external',
    internal: 'Reception_Management_Medical_Referrals.internal',
    code: 'Reception_Management_Medical_Referrals.code',
    info: 'Reception_Management_Medical_Referrals.info'
};
