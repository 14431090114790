import PropTypes from 'prop-types';
import React from 'react';

// MUI Components

import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

// hooks
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import useSidebar from 'hooks/useSidebar';

// project imports
import MenuList from './MenuList';
import Header from './Header';

// constants
import { CLOSED_DRAWER_WIDTH, DRAWER_WIDTH } from 'constant';
// ==============================|| SIDEBAR DRAWER ||============================== //

const DrawerHeader = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0
}));

const Sidebar = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const { open, handleToggle } = useSidebar();
    const drawer = (
        <React.Fragment>
            <DrawerHeader>
                <Header />
            </DrawerHeader>
            <MenuList />
        </React.Fragment>
    );

    return (
        <Box
            component="nav"
            aria-label="mailbox folders"
            sx={(theme) => ({
                flexShrink: { md: 0 },
                transition: '.3s all ease-in-out',
                width: matchUpMd ? (open ? DRAWER_WIDTH : CLOSED_DRAWER_WIDTH) : 'auto',
                [theme.breakpoints.up('md')]: {
                    margin: '10px'
                },
                height: 'calc(100vh - 28px)',
                overflow: 'hidden',
                borderRadius: theme.shape.borderRadius,
                borderRight: 'none',
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
                '*': {
                    color: '#FFFFFF !important'
                }
            })}
        >
            {matchUpMd ? (
                drawer
            ) : (
                <Drawer
                    open={open}
                    variant="temporary"
                    anchor="left"
                    onClose={handleToggle}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                    sx={{
                        '.MuiDrawer-paper': {
                            overflow: 'auto',
                            height: '100%',
                            width: '100%',
                            borderRight: 'none',
                            backgroundColor: theme.palette.primary.main,
                            '*': {
                                color: '#FFFFFF !important'
                            }
                        }
                    }}
                >
                    {drawer}
                </Drawer>
            )}
        </Box>
    );
};

Sidebar.propTypes = {
    open: PropTypes.bool,
    handleToggle: PropTypes.func,
    window: PropTypes.object
};

export default React.memo(Sidebar);
