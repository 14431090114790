export const CommonPageConstant = {
    password: 'Common.password',
    activationFrom: 'Common.activationfrom',
    activationTo: 'Common.activationto',
    isActive: 'Common.isactive',
    cancel: 'Common.cancel',
    reset: 'Common.reset',
    edit: 'Common.edit',
    delete: 'Common.delete',
    actions: 'Common.actions',
    search: 'Common.search',
    save: 'Common.save',
    add: 'Common.add',
    home: 'Common.home',
    true: 'Common.true',
    false: 'Common.false',
    activateAll: 'Common.activateall',
    activate: 'Common.activate',
    deactivateAll: 'Common.deactivateall',
    deactivate: 'Common.deactivate',
    activation: 'Common.activation',
    icon: 'Common.icon',
    close: 'Common.close',
    saveChanges: 'Common.savechanges',
    sureWantSaveChanges: 'Common.surewantsavechanges',
    table: 'Common.table',
    tree: 'Common.tree',
    treeList: 'Common.treelist',
    year: 'Common.year',
    month: 'Common.month',
    day: 'Common.day',
    week: 'Common.week',
    activated: 'Common.activated',
    invalideEmail: 'Common.invalideemail',
    phoneNumberIncorrect: 'Common.phonenumberincorrect',
    required: 'Common.required',
    createdDate: 'Common.createddate',
    updatedDate: 'Common.updateddate',
    pending: 'Common.pending',
    confirmed: 'Common.confirmed',
    rejected: 'Common.rejected',
    cancelled: 'Common.cancelled',
    didNotCome: 'Common.didNotCome',
    sponsor: 'Common.sponsor',
    selectLanguage: 'Common.selectlanguage',
    startDate: 'Common.startdate',
    endDate: 'Common.enddate',
    noData: 'Common.nodata',
    showTable: 'Common.showtable',
    showCalendar: 'Common.showcalendar',
    calendar: 'Common.calendar',
    showFilter: 'Common.showfilter',
    hideFilter: 'Common.hidefilter',
    loading: 'Common.loading',
    ok: 'Common.ok',
    filter: 'Common.filter',
    visits: 'Common.visits'
};
